<template>
    <div>
      <Pane />
      <a-modal width="800px" title="组织成员关系添加" :visible="addDialogVisible" :footer="null" @cancel="addDialogVisible = false">
        <a-form
          :label-col="{ span: 6 }"
          :wrapper-col="{ span: 14 }"
          :colon="false"
        >
          <a-row>
            <a-col :lg="12" :md="12" :sm="24">
              <a-form-item label="组织名称">
                <a-select
                  v-model="dialog.orgId"
                  placeholder="请选择"
                >
                  <a-select-option
                    v-for="item in organizationList"
                    :key="item.id"
                    :value="item.id"
                    >{{ item.uniqueName }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>

            <a-col :lg="12" :md="12" :sm="24">
              <a-form-item label="成员角色">
                <a-select v-model="dialog.role" placeholder="请选择">
                  <a-select-option
                    v-for="item in specialStaff"
                    :key="item.value"
                    :value="item.value"
                  >{{ item.name }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>

            <a-col :lg="12" :md="12" :sm="24">
              <a-form-item label="人员名称">
                <EmployeeSelector
                  @change="
                    (arr) => {
                      if (arr.length > 0) {
                        user = arr[0];
                      } else {
                        user = {};
                      }
                    }
                  "
                  :value="user.name ? [user] : []"
                >
                  <a-button block style="text-align: left">
                    <div v-if="user.name">
                      {{ user.name }}
                    </div>
                    <div v-else>&nbsp;</div>
                  </a-button>
                </EmployeeSelector>
              </a-form-item>
            </a-col>
          </a-row>
  
          <div class="center">
            <a-space>
                <a-button :loading="loading" type="primary" @click="addMember">添加</a-button>
                <a-button @click="addDialogVisible = false">关闭</a-button>
            </a-space>
          </div>
        </a-form>
      </a-modal>
      <a-modal width="800px" title="组织成员关系编辑" :visible="editDialogVisible" :footer="null" @cancel="editDialogVisible = false">
        <a-form
          :label-col="{ span: 6 }"
          :wrapper-col="{ span: 14 }"
          :colon="false"
          :form="dialog"
        >
          <a-row>
            <a-col :lg="12" :md="12" :sm="24">
              <a-form-item label="组织名称">
                <a-select
                  v-model="dialog.orgId"
                  placeholder="请选择"
                >
                  <a-select-option
                    v-for="item in organizationList"
                    :key="item.id"
                    :value="item.id"
                    >{{ item.uniqueName }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>

            <a-col :lg="12" :md="12" :sm="24">
              <a-form-item label="成员角色">
                <a-select v-model="dialog.role" placeholder="请选择">
                  <a-select-option
                    v-for="item in specialStaff"
                    :key="item.value"
                    :value="item.value"
                  >{{ item.name }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>

            <a-col :lg="12" :md="12" :sm="24">
              <a-form-item label="人员名称">
                <EmployeeSelector
                  @change="
                    (arr) => {
                      if (arr.length > 0) {
                        user = arr[0];
                      } else {
                        user = {};
                      }
                    }
                  "
                  :value="user.name ? [user] : []"
                >
                  <a-button block style="text-align: left">
                    <div v-if="user.name">
                      {{ user.name }}
                    </div>
                    <div v-else>&nbsp;</div>
                  </a-button>
                </EmployeeSelector>
              </a-form-item>
            </a-col>
          </a-row>
  
          <div class="center">
            <a-space>
                <a-button :loading="loading" type="primary" @click="editMember">保存</a-button>
                <a-button @click="editDialogVisible = false">关闭</a-button>
            </a-space>
          </div>
        </a-form>
      </a-modal>
      <a-card class="container">
        <a-row>
          <a-col :span="24">
            <a-form-model :colon="false" :model="form" layout="inline" @keyup.enter.native="query">  
              <a-form-model-item>
                <a-select
                  v-model="form.orgId"
                  placeholder="组织名称"
                  style="width: 150px"
                >
                  <a-select-option
                    v-for="item in organizationList"
                    :key="item.id"
                    :value="item.id"
                    >{{ item.uniqueName }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
  
              <a-form-model-item>
                <a-select v-model="form.role" placeholder="成员角色" style="width: 150px">
                  <a-select-option
                    v-for="item in specialStaff"
                    :key="item.value"
                    :value="item.value"
                  >{{ item.name }}</a-select-option>
                </a-select>
              </a-form-model-item>
  
              <a-form-model-item>
                <a-input v-model="form.userName" placeholder="人员名称" style="width: 150px"></a-input>
              </a-form-model-item>

              <a-form-model-item>
                <a-space>
                  <a-button @click="query" type="primary">查询</a-button>
                  <a-button @click="reset">重置</a-button>
                </a-space>
              </a-form-model-item>
              <a-button @click.prevent="showAddDialog" type="primary" style="float: right;margin-top: 4px">添加</a-button>
            </a-form-model>
          </a-col>
        </a-row>
  
        <Padding />
        <a-table
          bordered
          :data-source="list"
          :loading="loading"
          @change="onChange"
          :pagination="{
            total,
            current,
            pageSize,
            showTotal: (total) => `共 ${total} 条记录`,
          }"
          rowKey="id"
        >
          <a-table-column title="序号">
            <template slot-scope="text, record, index">
              {{ (current - 1) * pageSize + (index + 1) }}
            </template>
          </a-table-column>
          <a-table-column title="组织名称" data-index="orgName" />
          <a-table-column title="成员角色">
            <template slot-scope="text">
                <DataDictFinder
                dictType="qualitySafety.specialStaff"
                :dictValue="text.role"
                />
            </template>
          </a-table-column>

          <a-table-column title="人员名称" data-index="userName" />
          <a-table-column title="操作" width="110px">
            <template slot-scope="text">
              <a-space>
                <a
                  href="#"
                  @click.prevent="showEditDialog(text)"
                >编辑</a>
                <a
                  href="#"
                  class="danger"
                  @click.prevent="deleteText(text)"
                >删除</a>
              </a-space>
            </template>
          </a-table-column>
        </a-table>
        <div class="center">
          <a-space>
            <a-button @click="$close($route.path)">关闭</a-button>
          </a-space>
        </div>
      </a-card>
    </div>
  </template>
  
  <script>
  import EmployeeSelector from "@/components/employee-selector";
  import request from "@/api/request";
  import { mapGetters } from "vuex";
  function queryCompanyOrg() {
    return request({
      url: "/user-service/org/queryCompanyOrg",
    });
  }
  function fetchList(data) {
    return request({
      url: "/office-service/org/special/staff/queryWithPage",
      method: "post",
      data
    });
  }
  function save(data) {
    return request({
      url: "/office-service/org/special/staff/save",
      method: "post",
      data
    });
  }
  function edit(data) {
    return request({
      url: "/office-service/org/special/staff/modify",
      method: "post",
      data
    });
  }
  function remove(data) {
    return request({
      url: "/office-service/org/special/staff/delete",
      method: "post",
      data
    });
  }
  export default {
    components: {
        EmployeeSelector,
    },
    data() {
      return {
        id: '',
        user: {},
        form: {},
        dialog: {},
        loading: false,
        list: [],
        total: 0,
        current: 1,
        pageSize: 10,
        organizationList: [],
        addDialogVisible: false,
        editDialogVisible: false,
      };
    },
  
    computed: {
      ...mapGetters("setting", ["findDataDict"]),
      specialStaff() {
        return this.findDataDict("qualitySafety.specialStaff");
      },
    },
  
    mounted() {
      queryCompanyOrg().then(res => {
        this.organizationList = res;
        const { query } = this.$route;
        const { id } = query || {};
        this.$set(this.form, 'orgId', id);
        this.getList();
      })
      
    },
  
    methods: {
      showAddDialog() {
        this.addDialogVisible = true;
        this.dialog = {};
        this.user = {};
      },
      showEditDialog(text) {
        this.editDialogVisible = true;
        this.id = text.id;
        this.$set(this.dialog, 'orgId', text.orgId);
        this.$set(this.dialog, 'role', text.role);
        this.$set(this.user, 'id', text.userId);
        this.$set(this.user, 'name', text.userName);
      },
      addMember() {
        this.loading = true;
        save({
            orgId: this.dialog.orgId,
            orgName: this.organizationList.filter(v=>v.id == this.dialog.orgId)[0].uniqueName,
            role: this.dialog.role,
            userId: this.user.id,
            userName: this.user.name,
        })
        .then(() => {
          this.addDialogVisible = false;
          this.getList();
        })
        .finally(() => {
          this.loading = false;
        });
      },
      editMember() {
        this.loading = true;
        edit({
            id: this.id,
            orgId: this.dialog.orgId,
            orgName: this.organizationList.filter(v=>v.id == this.dialog.orgId)[0].uniqueName,
            role: this.dialog.role,
            userId: this.user.id,
            userName: this.user.name,
        })
        .then(() => {
          this.editDialogVisible = false;
          this.getList();
        })
        .finally(() => {
          this.loading = false;
        });
      },
      getList() {
        this.loading = true;
        fetchList({
          pageNum: this.current,
          pageSize: this.pageSize,
          ...this.form,
        })
          .then(res => {
            if (Array.isArray(res.list)) {
              this.list = res.list;
              this.total = res.totalSize || 0;
            }
          })
          .finally(() => {
            this.loading = false;
          });
      },
      deleteText(text) {
        const that = this;
        this.$confirm({
          title: "确认要删除吗？",
          onOk() {
            remove({
              id:text.id
            }).then(() => {
              that.getList();
            });
          }
        });
      },
      onChange(pagination) {
        this.current = pagination.current;
        this.pageSize = pagination.pageSize;
        this.getList();
      },
  
      query() {
        this.current = 1;
        this.getList();
      },
      reset() {
        this.current = 1;
        this.form = {};
        this.getList();
      },
    }
  };
  </script>
  